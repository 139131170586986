import 'bootstrap/dist/css/bootstrap.min.css';
import "./../css/style.css";
import locale from "./translations.js";
import ip from 'ip';
import { v4 as uuidv4 } from 'uuid';

var AisFactory = (function () {

  var BASE_URL = process.env.SLS_BASE_ENDPOINT;
  var API_KEY = process.env.SLS_API_KEY;
  var cacheId = "";
  var agreementFailedAttempt = 0;
  var uuid = "";
  var interactionId = "";
  var clientId = "";
  var clientChannelId = "";
  var supportedCulture = {};
  var ipAddress = "";
  var token = "";
  var language = getSessionLanguage();

  function addTextToDom(id, text, isplaceholder) {
    if (isplaceholder) {
      document.getElementById(id).placeholder = text;
    } else {
      document.getElementById(id).innerHTML = text;
    }
  }

  function getUuid() {
    var visitorUuId = uuidv4();
    var visitorId = visitorUuId.replace(/-/g, "").toString("hex").toUpperCase();
    return visitorId;
  }

  function callAPI(url, body, callback) {
    var xhttp = new XMLHttpRequest();
    xhttp.open("POST", `${BASE_URL}${url}`, true);
    xhttp.setRequestHeader('Content-type', 'application/json; charset=utf-8');
    xhttp.setRequestHeader('x-api-key', API_KEY);
    xhttp.setRequestHeader('Asurion-channel', 'OnlineScreenRepair');
    xhttp.responseType = 'json';
    xhttp.onload = function() {
      if (xhttp.status != 200) { // analyze HTTP status of the response
        console.log(`Error ${xhttp.status}: ${xhttp.statusText}`); // e.g. 404: Not Found
        callback({
          errorStatus: xhttp.status,
          errorMsg: xhttp.statusText
        });
      } else { // show the result
        try {
          callback(null, JSON.parse(xhttp.response));
        } catch(e) {
          callback(null, xhttp.response);
        }
      }
    };
    xhttp.onerror = function() {
      callback("Request Failed");
    };
    xhttp.send(JSON.stringify(body));
  }

  function callFindgreementApi(request) {
    show_loader()
    let requestBody = {
      FindAgreementsParameters: {
        MobileDeviceNumber: request.mdn,
        AgreementFailedAttempt: request.agreementFailedAttempt,
        SessionId: request.sessionId,
        VisitorId: uuid,
        CustomerName: request.fullName.trim(),
        FirstName: request.firstName,
        LastName: request.lastName || "user"
      }
    };
    callAPI("/api/incidentidentification/v2/findagreement", requestBody, function(error, response) {
      hide_loader();
      var errorEle = document.getElementById("error-msg");
      let locale = getLocaleByLang(language);
      if(!error) {
        if(response.Agreement.AgreementFound) {
          if(!errorEle.classList.contains("hide")) {
            errorEle.classList.add("hide"); 
          }
          setSessionStorageValue("data", JSON.stringify({
            MDN: request.mdn,
            sessionId: cacheId,
            visitorId: uuid,
            interactionLineId: interactionId,
            clientId: clientId,
            clientChannelId: clientChannelId,
            supportedCulture: supportedCulture,
            // ipAddress: ipAddress
          }));
          window.location.replace("./online/index.html");
          // window.location.replace('//localhost:3005/');
        } else {
          if(agreementFailedAttempt === 2) {
            setSessionStorageValue("data", JSON.stringify({
              MDN: request.mdn,
              sessionId: cacheId,
              visitorId: uuid,
              interactionLineId: interactionId,
              clientId: clientId,
              clientChannelId: clientChannelId,
              supportedCulture: supportedCulture,
              // ipAddress: ipAddress,
              showThanksPage: true
            }));
            window.location.replace("./online/index.html");
          } else {
            errorEle.classList.remove("hide");
            agreementFailedAttempt === 0 ? (errorEle.textContent = locale.mobileInvalid) : (errorEle.textContent = locale.mobileInvalidAttemptExceed);
            agreementFailedAttempt++;
          }
        }
      } else {
        agreementFailedAttempt++;
      }
    });
  }

  function callInteractionApi(cacheId) {
    let requestBody = {
      CreateInteraction: {
        Interaction: {
          SessionId: cacheId,
          AgentName: "User",
          InteractionType: "ONLINE",
          InteractionStatus: "INPRGRS",
          CacheId: cacheId,
          StartTime: new Date().toISOString(),
          ChannelName: "ONLINE",
          ClientProfileId: "6040",
          SourceIPAddress: ip.address(),
          ExternalSystemName: "ConsoleOne",
          SystemName: "Online",
          InteractionLine: {
            InteractionLineReason: "STRTSR",
            Language: language === 'th' ? 'th-TH' : 'en-US',
          }
        }
      }
    }

    callAPI("/api/incidentidentification/v1/interaction/create", requestBody, function(error, response) {
      interactionId = response.Interaction.InteractionLineId;
      clientId = response.Interaction.SessionData.ClientId;
      clientChannelId = response.Interaction.SessionData.ClientChannelId;
      ipAddress = response.Interaction.SourceIPAddress;
    });

  }

  function callInitializeApi() {
    let requestBody = {
      InitializeApplicationRequest: {
        Client: "AIS",
        Language: language === 'th' ? 'th-TH' : 'en-US',
      }
    };
    callAPI("/api/incidentidentification/v1/initialize", requestBody, function(error, response) {
      cacheId = response.Configurations.CacheId;
      supportedCulture = response.Configurations.SupportedCulture;
      callInteractionApi(cacheId);
    });
  }

  function getSessionStorageValue(name) {
    return sessionStorage.getItem(name);
  }
  function getLocaleByLang(lng) {
    return locale[lng];
  }
  function setSessionStorageValue(name, value) {
    return sessionStorage.setItem(name, value);
  }

  function getSessionLanguage() {
    let lang = getSessionStorageValue('language');
      if (!lang) {
        setSessionStorageValue('language', 'th');
        lang = "th";
      }
      return lang;
  }
  
  function getMobileFromTextFields() {
    var mobVal = document.getElementById('txt_sur_ais_mdn_id').value;
    return mobVal
  }

  /*function validateNumber() {
    var mobile = getMobileFromTextFields();
    
    if (mobile.trim()) {
      return true;
    } else {
      validateCaptcha();
    }
    return false;
  }

  function validateCaptcha() {
    var captchaErrorDiv = document.getElementsByClassName('desktopErrorMsg');
    var response = grecaptcha.getResponse();
    if(response.length == 0) {
      captchaErrorDiv[0].innerHTML = '<span class="error-msg">This field is required.</span>';
      return false;
    } else {
      captchaErrorDiv[0].innerHTML = '<span class="error-msg-hide"></span>';
      return true;
    }
  }

  function verifyCaptcha() {
    let captchaErrorDiv = document.getElementsByClassName("desktopErrorMsg");
    captchaErrorDiv[0].innerHTML = "";
    console.log(`catch callback called`);
  }*/

  function show_loader(){
    $("#loader").addClass("loader");
  }
  
  function hide_loader(){
    $("#loader").removeClass("loader");
  }

  function showMaintenanceText() {

    let d1 = new Date("2021-05-24 06:00");
    let d2 = new Date();

    return d1 < d2 ;
  }

  return {
    init: function () {

      const script = document.createElement('script');
      script.src = `https://www.google.com/recaptcha/api.js?hl=${language}`;
      document.body.appendChild(script);

    script.onload = () => {
    };

      uuid = getUuid();

      callInitializeApi();

      var lang = language;
      var lng = getLocaleByLang(lang);

      //add all text to DOM based on lang
      addTextToDom('ais_header', lng.Header);
      addTextToDom('ais_sub-header', lng.SubHeader);
      addTextToDom('ais_inputlabel-mobile', lng.InputLabel);
      addTextToDom('ais_disclaimer', lng.Disclaimer);
      addTextToDom('ais_payment_disclaimer', lng.PaymentDisclaimer);
      addTextToDom('ais_submitbtntext', lng.SubmitBtnText);

      // #footer fix for mobile devices overlapping issue with button
      // var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      // if (isMobile) {
      //   document.getElementById("ais_footer").style.position = "relative";
      //   document.getElementById("ais_footer").style.paddingTop = "20px";
      // }

      addTextToDom('ais_copyright', lng.Copyright);
      addTextToDom('ais_copyrightlink', lng.CopyrightLink);
      addTextToDom('ais_privacypolicy', lng.PrivacyPolicy);
      addTextToDom('ais_termsandcondition', lng.TermsAndCondition);
      addTextToDom('ais_termsofuse', lng.TermsOfUse);
      addTextToDom('ais_modal_sr_info-label', lng.LabelScreenRepair);
      addTextToDom('ais_modal_sr_info', lng.ScreenRepairInfo);
      // addTextToDom('maintenance-text', lng.maintenanceText);

      // if (showMaintenanceText()) {
      //   let divMaintenanceText = document.getElementsByClassName(
      //     "div_maintenance_text"
      //   );
      //   divMaintenanceText[0].innerHTML = "";
      // }

    },
    onSubmit: function(_token) {
      token = _token;
      var inputMdn = getMobileFromTextFields();
      if(cacheId) {
         if(agreementFailedAttempt < 3) {
           callFindgreementApi({
             mdn: inputMdn,
             agreementFailedAttempt: agreementFailedAttempt,
             firstName: "User",
             lastName: "User",
             fullName: "User",
             sessionId: cacheId
           });
         }
       }
     },
    changeLanguage: function (e) {
      language = e.target.name || e.target.dataset.lang || e.target.getAttribute("data-lang");
      setSessionStorageValue('language', language);
    },
    keypressHandler: function (e) {
      if (e.keyCode === 13) { // 13 is enter
        window.AisFactory.submitHandler();
      }
    },
    handleKeyDown: function (e) {
      const key = e.key;
      var buttonEle = document.getElementById("ais_submitbtntext");
      if (key === "Backspace" || key === "Delete") {
          buttonEle.disabled = true;
      }
    },
    handlePaste: function (e) {
      var buttonEle = document.getElementById("ais_submitbtntext");
      var clipboardData = window.clipboardData || e.clipboardData || e.originalEvent.clipboardData;
      var value = clipboardData.getData('text');
        if(value.length >= 9 ) {
          buttonEle.disabled = false;
        } else {
          buttonEle.disabled = true;
        }
    },
    handleChange: function (e) {
      var currentEl = e.currentTarget;
      var buttonEle = document.getElementById("ais_submitbtntext");
      var value = e.target.value;
      // var pattern = /^[0-9]+$/;
      // if(pattern.test(value)) {
        if(value.length >= 9 ) {
          buttonEle.disabled = false;
        } else {
          buttonEle.disabled = true;
        }
        if (e.keyCode === 13) {
          window.AisFactory.submitHandler();
        } else if (currentEl.classList.contains('errorBorder')) {
          currentEl.classList.remove('errorBorder')
        }
      // }
    },
    submitHandler: function (e) {
      e.preventDefault();
      if (process.env.REACT_APP_ENV === "QA") {
        window.onSubmit(token);
      } else {
        if (!token) {
          grecaptcha.execute();
        } else {
          window.onSubmit(token);
        }
      }
    },
    selectTnCLink: function () {
      if (language === "th") {
        window.open('./terms-conditions-thai.pdf', '_blank');
      } else {
        window.open('./terms-conditions.pdf', '_blank');
      }
    },
    selectTofUseLink: function () {
      if (language === "th") {
        window.open('./terms-of-use-thai.pdf', '_blank');
      } else {
        window.open('./terms-of-use.pdf', '_blank');
      }
    },
    selectPrivacyLink: function () {
      if (language === "th") {
        window.open('http://www.ais.co.th/privacypolicy/th/?intcid=getpage:en:footer_menu:sitemap_menu:aboutais_submenu1:privacypolicy_submenu2', '_blank');
      } else {
        window.open('http://www.ais.co.th/privacypolicy/en/?intcid=getpage:en:footer_menu:sitemap_menu:aboutais_submenu1:privacypolicy_submenu2', '_blank');
      }
    },
    showFAQ: function () {
      window.open('./screen-repair-faq.html', '_blank');
    }
  }
})();

window.onload = function () {
  window.onSubmit = function(token) { 
    AisFactory.onSubmit(token);
  }

  AisFactory.init();
  
  document.getElementById('ais_lang-en').addEventListener('click', AisFactory.changeLanguage);
  document.getElementById('ais_lang-th').addEventListener('click', AisFactory.changeLanguage);
  document.getElementById('ais_lang-en-mobile').addEventListener('click', AisFactory.changeLanguage);
  document.getElementById('ais_lang-th-mobile').addEventListener('click', AisFactory.changeLanguage);
  document.getElementById('ais_submitbtntext').addEventListener('click', AisFactory.submitHandler);
  document.getElementsByClassName('ais_input-mobile-number')[0].addEventListener('keypress', AisFactory.handleChange);
  document.getElementsByClassName('ais_input-mobile-number')[0].addEventListener('keydown', AisFactory.handleKeyDown);
  document.getElementsByClassName('ais_input-mobile-number')[0].addEventListener('paste', AisFactory.handlePaste);
  document.getElementById('ais_submitbtntext').addEventListener('keypress', AisFactory.keypressHandler);
  document.getElementById('ais_termsandcondition').addEventListener('click', AisFactory.selectTnCLink);
  document.getElementById('ais_termsofuse').addEventListener('click', AisFactory.selectTofUseLink);
  document.getElementById('ais_privacypolicy').addEventListener('click', AisFactory.selectPrivacyLink);
  document.getElementById('ais_copyrightlink').addEventListener('click', AisFactory.showFAQ);
}